import { Icons } from "~/components/Icons";
import { Flex, Text } from "@radix-ui/themes";

export function Footer() {
  return (
    <footer>
      <Flex
        height="var(--footer-height)"
        gap="2"
        justify="center"
        align="center"
      >
        <Icons.vendr height="20" />
        <Text color="gray">2024 Vendr. All rights reserved.</Text>
      </Flex>
    </footer>
  );
}
